import OsintGraph from "@/components/osint-graph";

export default {
    name: "OsintGraphView",
    props: [],
    components: {
        OsintGraph,
    },
    data() {
        return {};
    },
};
